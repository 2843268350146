import React, { Component, Fragment, lazy, Suspense } from 'react';
import { hot } from 'react-hot-loader'
import { BrowserRouter as Router, Route, Link, Redirect, withRouter, Switch} from 'react-router-dom'
import { TransitionGroup, Transition } from 'react-transition-group';
import { createBrowserHistory } from 'history';
import {TweenLite} from 'gsap'
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';

import Navbar from 'components/Navbar'
import Footer from 'components/Footer'
import Home from 'pages/Home'
import About from 'pages/About'
import Error404 from 'pages/Error404'
const Contact = ( lazy( () => import('pages/Contact') ) )

// lazy, font preload, serverworker, duplicator


let history = createBrowserHistory();
let historyObject = history.location.pathname;

const completeCall = target => {
   TweenLite.set(target, { clearProps: "position, width, transform" });
};

const Loading = () => (
   "I'm loading..."
)
const ContactCom = () => (
   <Contact />
)

class App extends Component {

   componentWillMount() {
      this.unlisten = history.listen((location, action) => {
         historyObject = location.pathname;
      });
   }
   componentWillUnmount() {
      this.unlisten();
   }


   render() {
      return (
         <Router>
            <Route
               render={({ location }) => (
                  <Fragment >
                     <Route exact path="/" />
                     <Navbar />

                     <TransitionGroup>
                        <Transition
                           key={location.key}
                           timeout={500}
                           mountOnEnter={true}
                           unmountOnExit={true}

                           onEnter={node => {
                              TweenLite.killTweensOf(node);
                              setTimeout( () => {
                                 document.body.scrollTop = document.documentElement.scrollTop = 0;
                              }, 400)
                              const parent = node.parentNode;
                              const targetWidth = parent.clientWidth - parseFloat(getComputedStyle(node.parentNode).paddingLeft) * 2;
                              TweenLite.set(node, {
                                 x: 150,
                                 autoAlpha: 0,
                                 width: targetWidth,
                                 display: 'none',
                              });
                              TweenLite.to(node, 0.5, {
                                 display: 'block',
                                 autoAlpha: 1,
                                 x: 0,
                                 onComplete: completeCall,
                                 onCompleteParams: [node],
                                 delay: 0.5
                              });
                           }}
                           onExit={node => {
                              TweenLite.killTweensOf(node);
                              const parent = node.parentNode;
                              const targetWidth = parent.clientWidth - parseFloat(getComputedStyle(node.parentNode).paddingLeft) * 2;
                              TweenLite.set(node, {
                                 width: targetWidth
                              });
                              TweenLite.to(node, 0.4, {
                                 opacity: 0,
                                 x: -100
                              });
                           }}>
                           <div id="parent" >
                              <Suspense fallback={ <Loading /> }>
                              <Switch location={location}>
                                 <Route path="/" component={Home} exact />
                                 <Route path="/about-us" component={About} />
                                 <Route path="/contact" render={()=><Contact />} />
                                 {/* <Route path="/about-us" component={AboutUs}/>
                                 <Route path="/team" component={Team} />
                                 <Route path="/careers" component={Careers} />
                                 <Route path="/terms-and-conditions" component={TermsCondition} />
                                 <Route path="/privacy-policy" component={PrivacyPolicy} /> */}

                                 <Route component={Error404} />
                              </Switch>
                           </Suspense>

                           </div>

                        </Transition>


                     </TransitionGroup>
                     <Footer />
                  </Fragment>
               )}
            />
         </Router>
      );
   }
}

export default hot(module)(App);
