import React, { Component } from 'react';
import {TweenMax} from 'gsap'
import {Link} from 'react-router-dom'
import Fade from 'react-reveal/Fade';


class Home extends Component {

   constructor() {
      super()
      this.state = {

      }
   }

   componentDidMount() {

   }


   render () {
      return (
         <div className="container">

            <p>Home</p>

         </div>
      )

   }


}

export default Home
