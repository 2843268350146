import React, { Component } from 'react';
import {TweenMax} from 'gsap'
import {Link} from 'react-router-dom'

import Logo from 'assets/images/logo.png'

class Footer extends Component {



   render () {
      let pathname = window.location.pathname.split('/')[1];

      return (
         <div id="footer" >
            <div className="container" >
               <div >
                  <div id="footer-1" className="d-none d-sm-block">
                     <p>Footer</p>
                  </div>
                  <div id="footer-1-mobile" className="d-sm-none">
                     <img src={Logo} id="logo-square" className="logo" />
                  </div>

                  <div id="footer-2">
                     <div id="footer-2-inner">
                        <h5 className="h5">GET IN TOUCH</h5>
                        <div className="contacts">
                           <p><a href='tel:+6567899876'>+65 1234 5678</a></p>
                        </div>
                        <div className="contacts">
                           <p><a href="mailto:enquires@aa.com" >enquires@aa.com</a></p>
                        </div>
                        <div className="contacts">
                           <p><a href='https://goo.gl/maps/DPhf1B3xHWE2' target="_blank">46 Battery Road, #01-01,<br/>Singapore 123123</a></p>
                        </div>
                     </div>
                  </div>

                  <div id="footer-3" className="row">
                     <div className="col-sm"> <Link to='/' >HOME</Link></div>
                     <div className="col-sm"> <Link to='/about-us' >ABOUT US</Link></div>
                     <div className="col-sm"> <Link to='/contact' >CONTACT US</Link></div>
                     <div className="col-sm"> <Link to='/' >Links</Link></div>
                  </div>

               </div>

               <hr id="footer-seperator-mobile" />

               <div id="footer-bottom">
                  <p>Copyright &copy; {(new Date()).getFullYear()} Company. <br className="hide-lg" />All rights reserved.</p>
                  <div id="footer-links" className="row">
                     <div className="col-sm"><Link to='privacy-policy'>Privacy Policy</Link></div>
                     <div className="col-sm"><Link to='terms-and-conditions'>Terms & Conditions</Link></div>
                  </div>
               </div>
            </div>
         </div>
      )
   }


}

export default Footer
