import React, { Component } from 'react';
import {TweenMax} from 'gsap'
import {Link} from 'react-router-dom'


class Home extends Component {


   render () {

      return (
         <div id="error" className="container">
            <h1 className="h1">404</h1>
            <h3>PAGE NOT FOUND</h3>
            <p>Sorry, the page you're looking for doesn't exist or<br/>another error occurred.</p>
            <Link to="/" className="btn"
            >
               Back to Home
            </Link>
         </div>
      )
   }


}

export default Home
