import React, { Component } from 'react';
import {TweenMax} from 'gsap'
import Logo from 'assets/images/logo.png'
import {Link} from 'react-router-dom'

import BurgerIcon from 'assets/images/burger.svg'
import Close from 'assets/images/close.svg'

class Navbar extends Component {


   openBurger = () => {
      TweenMax.set('body', {overflow: 'hidden'})
      TweenMax.to("#nav-overlay", 0.3, {
         opacity: 1,
         display: 'block'
      })
      TweenMax.to('#nav-block', 0.3,{
         opacity: 1,
         display: 'block'
      })
   }

   closeMobileNav = () => {
      TweenMax.set('body', {overflow: 'auto'})
      TweenMax.to("#nav-overlay", 0.3, {
         opacity: 0,
         display: 'none'
      })
      TweenMax.to("#nav-block", 0.3, {
         opacity: 0,
         display: 'none'
      })
   }

   render () {
      let pathname = window.location.pathname.split('/')[1];
      return (
         <div id="navbar">
            <div className="container" >
               <Link to="/" ><img src={Logo} id="logo" className="logo" /></Link>
               <div id="links-desktop" className="d-none d-sm-block">
                  <Link to='/' className={pathname === '' ? 'menu-active' : ''}  >Home</Link>
                  <Link to='/about-us' className={pathname === 'about-us' ? 'menu-active' : ''}  >About us</Link>
                  <Link to='/contact' className={pathname === 'contact' ? 'menu-active' : ''}  >Contact</Link>
                  {/* <Link to='/team' className={pathname === 'team' ? 'menu-active' : ''}  >Team</Link>
                  <Link to='/careers' className={pathname === 'careers' ? 'menu-active' : ''}  >Careers</Link> */}
               </div>

               <div id="links-mobile" className="d-sm-none" >
                  <div id="burger" onClick={this.openBurger} >
                     <img src={BurgerIcon} width="20px" />
                  </div>
                  <div id="nav-block">
                     <div id="close" onClick={this.closeMobileNav} >
                        <img  src={Close} width="20px" />
                     </div>
                     <img id="logo-icon" className="logo" src={Logo} width="50px" />
                     <Link to='/' onClick={this.closeMobileNav} >HOME</Link>
                     <Link to='/about-us' onClick={this.closeMobileNav} >ABOUT US</Link>
                     <Link to='/contact' onClick={this.closeMobileNav} >Contact</Link>
                     {/* <Link to='/team' onClick={this.closeMobileNav} >TEAM</Link>
                     <Link to='/careers' onClick={this.closeMobileNav} >CAREERS</Link>
                     <Link to='/contact' onClick={this.closeMobileNav} >CONTACT</Link> */}
                  </div>
                  <div id="nav-overlay" className="overlay" onClick={this.closeMobileNav} />
               </div>
            </div>
         </div>
      )
   }


}

export default Navbar
